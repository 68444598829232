<template>
  <a-form-item>
    <slot></slot>
  </a-form-item>
</template>

<script>
export default {
  name: "UiFormItem",
}
</script>

<style>
.ant-form-item {
  margin-bottom: 16px;
}
</style>
