<template>
  <a-input-password size="large" v-bind="{ ...$attrs, ...$props }" />
</template>

<script>
export default {
  inheritAttrs: false,
  name: "UiPasswordInput",
}
</script>

<style lang="scss"></style>
