/* eslint-disable */
/*

позволяет использовать v-model для state из vuex
выполняет изменение значения через мутацию

example:
      ...mapFields({
        message: "message"
      }, "module"),
      ...mapFields({
        message: "module.message.str"
      }),
*/

import Vue from 'vue'

export default function mapFields(fields, storeKey) {
  const computed = {}
  for (const key in fields) {
    const path = fields[key].split('.')
    if (storeKey) path.unshift(storeKey)

    // console.log('map ' + key, path);

    computed[key] = {
      get() {
        let ptr = this.$store.state
        for (let i = 0; i < path.length; i++) {
          if (!ptr[path[i]]) {
            return undefined
          }
          ptr = ptr[path[i]]
        }
        return ptr
      },
      set(value) {
        const moduleName = 'mapFieldsModule'
        if (!this.$store.hasModule(moduleName)) {
          console.log('register mapFieldsModule')
          this.$store.registerModule(moduleName, {
            mutations: {
              updateField(state, { path, value }) {
                let ptr = this.state
                for (let i = 0; i < path.length; ++i) {
                  if (!ptr[path[i]]) {
                    // ptr[path[i]] = {};
                    Vue.set(ptr, path[i], {})
                  }
                  if (i < path.length - 1) {
                    ptr = ptr[path[i]]
                  } else {
                    // ptr[path[i]] = value;
                    Vue.set(ptr, path[i], value)
                  }
                }
              },
            },
            // namespaced:false, // по умолчанию и так false
          })
        }

        this.$store.commit('updateField', { path, value })
      },
    }
  }
  return computed
}
