<route>
    {
    "name": "login",
    "meta": {
    "auth": false
    }
    }
</route>

<template>
  <div class="login">
    <div class="title-24 mb-32 center">{{ $t('login.log_in') }}</div>

    <ui-form
      layout="vertical"
      :model="values"
      :rules="rules"
      :hideRequiredMark="true"
      @form="form = $event"
      @submit.prevent="handleSubmit"
    >
      <ui-form-item label="Your email" name="email">
        <ui-text-input v-model:value="values.email" />
      </ui-form-item>
      <div class="relative">
        <ui-link class="forgot-password-link" to="/reset-password-step1">
          {{ $t('login.forgot_password') }}
        </ui-link>
        <ui-form-item label="Your password" name="password">
          <ui-password-input v-model:value="values.password" />
        </ui-form-item>
      </div>
      <ui-button
        wide
        class="mt-16"
        :awaiting="awaitingLoginResponse"
        @click="handleSubmit"
        >{{ $t('login.log_in') }}
      </ui-button>
    </ui-form>
  </div>
</template>

<script>
import mapFields from '../utils/mapFields'
// import promiseValidateFields from '../utils/promiseValidateFields';

import UiForm from '../components/ui/UiForm'
import UiFormItem from '../components/ui/UiFormItem'
import UiTextInput from '../components/ui/UiTextInput'
import UiButton from '../components/ui/UiButton'
import UiPasswordInput from '../components/ui/UiPasswordInput'
import UiLink from '../components/ui/UiLink'

export default {
  components: {
    UiForm,
    UiFormItem,
    UiTextInput,
    UiPasswordInput,
    UiButton,
    UiLink,
  },
  layout: 'auth',
  name: 'login',

  data() {
    return {
      text: '',
      isAccountExist: false,
      form: {},
      values: {
        email: '',
        password: '',
      },
      // Triggers will be removed on cred error
      rules: {
        email: [
          {
            trigger: 'blur',
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            whitespace: true,
            message: 'Please, enter a valid email',
          },
          {
            validator: (rule, value, callback) => {
              if (this.invalidCredentials) {
                callback(new Error(' '))
              } else {
                callback()
              }
            },
          },
        ],
        password: [
          {
            trigger: 'blur',
            required: true,
            min: 8,
            message: 'Must be at least 8 characters',
          },
          {
            validator: (rule, value, callback) => {
              if (this.invalidCredentials) {
                callback(
                  new Error('Email or password is incorrect. Please, try again')
                )
              } else {
                callback()
              }
            },
          },
        ],
      },

      btnDisabled: true,
      invalidCredentials: false,
      awaitingLoginResponse: false,
      hadValidationError: false,
    }
  },
  computed: {
    ...mapFields({
      user: 'user',
    }),
  },
  created() {
    // const {alreadyExistEmail} = this.$route.query;
    //
    // if (alreadyExistEmail) {
    //     this.isAccountExist = true;
    //     this.values.email = alreadyExistEmail;
    // }
    // const { resetFields, validate, validateInfos } = useForm(this.values, this.rules);
    // this.resetFields = resetFields;
    // this.validate = validate;
    // this.validateInfos = validateInfos;
  },
  methods: {
    redirect() {
      if (this.$route.params.nextUrl) {
        this.$router.push(this.$route.params.nextUrl)
      } else {
        this.$router.push('/time-tracker')
      }
    },

    async handleSubmit() {
      try {
        this.awaitingLoginResponse = true
        await this.form.validate()
        await this.$store.dispatch('auth/authenticate', {
          strategy: 'local',
          ...this.values,
        })
        /*
                      No need to set button awaiting animation to false after request is successful
                      because we want to avoid flickering during redirection
                    */
        this.redirect()
      } catch (err) {
        this.awaitingLoginResponse = false
        if (err.code === 401) {
          // Show invalid credentials error message
          this.invalidCredentials = true
          try {
            await this.form.validate()
          } finally {
            // reset flag, otherwise the validation will always return an error
            this.invalidCredentials = false
          }

          this.$notify.error('Invalid credentials!')
        } else {
          this.$notify.parseFeathersErrors(err)
        }
      }
    },
  },
}
</script>

<style scoped>
.login {
  padding: 45px 70px;
}

.center {
  text-align: center;
}

.relative {
  position: relative;
}

.forgot-password-link {
  position: absolute;
  right: 0;
  z-index: 10;
  font-size: 14px;
}
</style>
