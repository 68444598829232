<template>
  <a-form ref="form">
    <slot></slot>
  </a-form>
</template>

<script>
export default {
  name: "UiForm",
  mounted() {
    this.$emit("form", this.$refs.form)
  },
}
</script>

<style scoped></style>
